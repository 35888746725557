import 'leaflet/dist/leaflet.css';

import { latLng, icon } from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';

import common from '../mixins/common';

export default {
  name: 'single-shop',

  mixins: [common],

  data() {
    return {
      shops: [],
      zoom: 13,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5
      },
      byPhone: false,
      customPin: icon({
        iconUrl: '/content/uploads/2022/02/pin-1.png',
        iconSize: [32, 42],
        iconAnchor: [16, 0],
      }),
    };
  },

  async mounted() {
    const uri = window.location.href.split('?');

    if (uri.length == 2) {
      const vars = uri[1].split('&');
      vars.forEach((v) => {
        const qs = v.split('=');
        if (qs[0] === 'search') this.locationAutocompleteSearch = window.decodeURIComponent(qs[1]);
        if (qs[0] === 'phone') this.byPhone = this.locationAutocompleteByPhone = qs[1] == 'true';
      });
    }

    const shopsRequest = await fetch('/wp-json/wp/v2/shops?page=1&per_page=1');
    const totalShops = shopsRequest.headers.get('x-wp-totalpages');
    const totalShopsArray = Array.from({length: Math.ceil(totalShops/10)}, (_, i) => i + 1);

    for (const page of totalShopsArray) {
      let response;
      try {
        const request = await fetch (`/wp-json/acf/v3/shops?page=${page}&per_page=10`);
        response = await request.json();
      } catch (error) {
        this.$log.error(error);
      }

      const normalisedShops = response.map((shop) => {
        shop.latlng = latLng(shop.acf.posizione.lat, shop.acf.posizione.lng);
        return shop;
      })
      .filter((shop) => this.byPhone ? shop.acf.ordini_telefonici : true);

      this.shops.push(...normalisedShops);
    }
  },

  methods: {

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    }
  },

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
};
