import { throttle as _debounce } from 'lodash';

export default {
  name: 'common',

  data() {
    return {
      locationAutocompleteSearch: '',
      locationAutocompleteByPhone: false,
      locationAutocompleteSpeaking: false,
      locationResults: [],
    }
  },

  methods: {
    startTxtToSpeech() {
      // initialisation of voicereco
      window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new window.SpeechRecognition();
      recognition.lang = 'it-IT';
      recognition.interimResults = true;
      this.locationAutocompleteSpeaking = true;
      let text = '';
      // event current voice reco word
      recognition.addEventListener('result', event => {
        text = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('');
      });

      setTimeout(() => {
        this.locationAutocompleteSpeaking = false;
        recognition.stop();
        this.locationAutocompleteSearch = text;
        this.locationAutocompleteChange();
      }, 5000);

      recognition.start();
    },

    locationAutocompleteChange: _debounce(async function () {
      if (!this.locationAutocompleteSearch) return;
      const request = await fetch(`/wp-json/wp/v2/search?search=${encodeURIComponent(this.locationAutocompleteSearch)}&type=post&subtype=shop&per_page=5`);
      const response = await request.json();
      this.locationResults = await Promise.all(response.map(async (result) => {
        const request = await fetch(result._links.self[0].href);
        return request.json();
      }));
    }, 500),
  },

};
