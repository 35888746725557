import common from '../mixins/common';

export default {
  name: 'tax-area',

  mixins: [common],

  data() {
    return {
      counties: {},
      byPhone: false,
    };
  },

  computed: {
    activeCounties() {
      const keys = Object.keys(this.counties);
      const active = keys.filter((county) => this.counties[county]);
      return active;
    },
  },
};
