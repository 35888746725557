import { RadioSvgMap } from 'vue-svg-map';
import Italy from '@svg-maps/italy';
import 'vue-svg-map/dist/index.css';

import common from '../mixins/common';

export default {
  name: 'stores',

  mixins: [common],

  data() {
    return {
      shops: [],
      byPhone: false,
      selectedLocation: null,
      regions: [],
      Italy,
    };
  },

  async mounted() {
    let terms;
    try {
      const request = await fetch('/wp-json/wp/v2/area?parent=0&per_page=21&page=1');
      const response = await request.json();
      this.regions = response.map((region) => ({ count: region.count, name: region.slug, id: region.id }));
    } catch (error) {
      this.$log.error(error);
    }

    this.$refs.svgmap.locations.forEach((region) => {
      const pathBox = region.getBBox();
      const index = this.regions.findIndex((element) => element.name === region.getAttribute('id'));

      if (!this.regions[index].count) return;

      const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      circle.setAttribute('cx', pathBox.x + (pathBox.width / 2));
      circle.setAttribute('cy', pathBox.y + (pathBox.height / 2));
      circle.setAttribute('r', 15);
      circle.setAttribute('pointer-events', 'none');
      circle.setAttribute('fill', '#1b245c');
      text.setAttribute('x', pathBox.x + (pathBox.width / 2));
      text.setAttribute('y', pathBox.y + (pathBox.height / 2));
      text.setAttribute('text-anchor', 'middle');
      text.setAttribute('pointer-events', 'none');
      text.setAttribute('fill', 'white');
      text.setAttribute('font-size', '10px');
      text.setAttribute('dy', '.3em');
      text.textContent = this.regions[index].count;

      g.appendChild(circle);
      g.appendChild(text);
      this.$refs.svgmap.$el.appendChild(g);
    });
  },

  methods: {
    mapSelection(region) {
      window.location.href = `/area/${region}/`;
    },
  },

  components: {
    RadioSvgMap,
  },
};
